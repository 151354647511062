import helper from '@/app/utils/helper';
import { ElMessage } from 'element-plus';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsAvatar from "@/app/components/bs-avatar/BsAvatar.vue";

export default {
	name: 'NotifUserInfo',
	components: {
		SvgIcon, BsAvatar,
	},
	props: {
		user: {
			type: Object,
			default: ()=>({
				avatar: '',
				name: '',
				firstName: '',
				lastName: '',
				contacts: {
					email: '',
					phone: '',
					telegram: '',
				},
			}),
		},
		colorData: {
			type: Object,
			// default: ()=>({
			// 	lbAvatar: '#303133',
			// 	bgAvatar: '#C4BFE1',
			// 	label: '#C4BFE1',
			// }),
		}
	},
	computed: {
		avatar(){
			return this.user?.avatar ?? '';
		},
		contacts(){
			return this.user?.contacts ?? {};
		},
		fullName(){
			const { name, firstName, lastName } = this.user ?? {};
			let fullName = 'пользователь';

			if( name?.length ){
				let [ lastName, firstName ] = name.trim().split(' ');
				let initials = '';

				if(firstName?.trim()) initials = firstName.slice(0,1).toLocaleUpperCase() + '.';
				fullName = `${lastName} ${initials}`.trim();
			}
			else if(firstName?.length || lastName?.length){
				let initials = (firstName || '').trim().slice(0,1).toLocaleUpperCase();
				if(initials) initials = initials + '.';
				fullName = `${lastName || ''} ${initials}`.trim();
			}

			return helper.upFirstLetter(fullName);
		},
	},
	methods: {
		async clickPhone(){
			const { phone } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(phone);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			const { email } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(email);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			const { telegram } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(telegram);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyLinkTelegram');
		},
	},
}