import dayjs from 'dayjs';

export default {
	computed: {
		date(){
			const { createAt } = this.data;
			let date = createAt;

			if(!date) date = new Date();
			let curFormatDate = dayjs(date).format('YYYY-MM-DD');
			let todayFormat = dayjs().format('YYYY-MM-DD');

			// console.log({curFormatDate, todayFormat, date});
			if(todayFormat==curFormatDate){
				const countHours = dayjs().diff(date, 'hour', true);
				if(countHours>6) return dayjs(date).format('HH:mm');
				else {
					if(countHours < 1) return 'менее часа назад';
					else if(countHours >=1 && countHours < 2) return '1 час назад';
					else if(countHours >=2 && countHours < 5) return `${Math.floor(countHours)} часа назад`;
					else if(Math.floor(countHours) == 5) return `${Math.floor(countHours)} часов назад`;
				}
			}
			else return dayjs(date).format('DD.MM.YYYY');
		}
	},
	methods: {
		goToTargetProgram(){
			const { targetProgramId } = this.data;
			if(targetProgramId) window.open(`/target-programs/${targetProgramId}`, '_blank');
		}
	},
}