import mixinNotifMessage from '../mixins/mixin-notif-message';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import NotifSystemInfo from '@/app/components/notification/other/notif-system-info/NotifSystemInfo.vue';

export default {
	name: 'NotifMessageSystem',
	mixins: [mixinNotifMessage],
	components: {
		BsBtn,
		NotifSystemInfo,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({
				id: '',
				targetProgramId: '',
				createAt: '',
				senderType: '',
				notifType: '',
				isRead: true,
				message: '',
			}),
		}
	},
}