import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "notif-message-system__head" }
const _hoisted_2 = { class: "notif-message-system__info" }
const _hoisted_3 = { class: "notif-message-system__date" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "notif-message-system__btn-actions"
}
const _hoisted_6 = {
  key: 1,
  class: "notif-message-system__btn-actions"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NotifSystemInfo = _resolveComponent("NotifSystemInfo")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notif-message-system", { '--is-message-not-read': !_ctx.data?.isRead }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NotifSystemInfo)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.date), 1 /* TEXT */)
    ]),
    _createElementVNode("div", {
      class: "notif-message-system__message",
      innerHTML: _ctx.data?.message
    }, null, 8 /* PROPS */, _hoisted_4),
    (_ctx.data?.notifType=='agreement')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            size: "small",
            onClick: _cache[0] || (_cache[0] = () => {})
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Согласовать")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_BsBtn, {
            size: "small",
            onClick: _cache[1] || (_cache[1] = () => {})
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Не согласовать")
            ])),
            _: 1 /* STABLE */
          })
        ]))
      : (_ctx.data?.notifType=='tp-read')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              onClick: _ctx.goToTargetProgram
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Перейти к АП")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]))
        : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}