import ky from "ky";
import helper from "./helper";
// import '@/mock-server/server';

class ServiceApi{
	constructor(){
		this.base = '/api/';
		if(['localhost', 'test-erp.devovs.ru'].includes(window.location.hostname)){
			this.base = 'https://outplan-dev.devtestprod.ru/api/';
			// this.base = 'https://outplan-test.devtestprod.ru/api/';
		}

		this.api = ky.create({
			prefixUrl: this.base,
			timeout: 60000,
			headers:{
				'x-dev-token': 'dev_rim_erp', // dev - разработческий токен
			}
		});
	}

	buildParams(params){
		const searchParams = new URLSearchParams();

		if(params) for(let [key, value] of Object.entries(params)){
			if (Array.isArray(value)) {
				value.forEach(value => searchParams.append(`${key}[]`, value.toString()))
				// value.forEach(value => searchParams.append(`${key}`, value.toString()))
			} else {
				searchParams.append(key, value.toString())
			}
		}

		return searchParams;
	}

	getParamsObject(params){
		const paramsObject = {};
		for (const [key, value] of params) {
			if (!paramsObject[key]) {
				paramsObject[key] = value;
			} else {
				if (Array.isArray(paramsObject[key])) {
					paramsObject[key].push(value);
				} else {
					paramsObject[key] = [paramsObject[key], value];
				}
			}
		}

		return paramsObject;
	}

	async getData({ model, params, isTimer }){
		const label = `${helper.hash}-get-${model}`;
		const searchParams = this.buildParams(params);

		if(isTimer) console.time(label);
		const res = await this.api.get(model, { searchParams }).json();
		if(isTimer) console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async getDataItem({ model, id, params }){
		const label = `${helper.hash}-get-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.get(`${model}/${id}`, { searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async postDataItem({ model, id, data, params }){
		const label = `${helper.hash}-post-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.post(`${model}/${id}`, { json: data, searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async postData({ model, data, params, isTimer }){
		const label = `${helper.hash}-post-${model}`;
		const searchParams = this.buildParams(params);

		if(isTimer) console.time(label);
		const res = await this.api.post(model, { json: data, searchParams }).json();
		if(isTimer) console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async postFormData({ model, data }){
		const label = `${helper.hash}-postFormData-${model}`;
		const formData = new FormData();

		for( const [key, value] of Object.entries(data)){
			formData.append(key, value);
		}

		console.time(label);
		const res = await this.api.post(model, { body: formData }).json();
		console.timeEnd(label);

		return res;
	}

	async putData({ model, id='', data, params }){
		const label = `${helper.hash}-put-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.put(`${model}/${id}`, { json: data, searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async deleteData({ model, id, params }){
		const label = `${helper.hash}-delete-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.delete(`${model}/${id}`, { searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async getFileData({ path, params }){
		const label = `${helper.hash}-getFile-${path}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.get(path, { searchParams }).blob();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}
}

export default new ServiceApi();