import { mapActions } from "pinia";
import { useUserStore } from "@/app/stores/user-store";

import { ElButton, ElForm, ElInput, ElMessageBox } from "element-plus";



import 'element-plus/es/components/input/style/css';

import MainHeadLogo from '@/app/components/main-head-logo/MainHeadLogo.vue';

export default {
	name: 'sign-in-view',
	components: {
		ElForm, ElInput, ElButton,
		MainHeadLogo,
	},
	data() {
		let email = '';
		let password = '';
		return {
			email, password
		}
	},
	methods: {
		...mapActions(useUserStore, ['signIn']),
		async clickSignIn() {
			const res = await this.signIn(this.email, this.password);
			// console.log(res);

			if(!res){
				ElMessageBox.alert( null, null, {
					title: 'Ошибка!',
					message: 'Что-то пошло не так... <br> Проверьте правильность логина или пароля.',
					type: 'error',
					customClass: 'bs-message-box',
					showClose: false,
					dangerouslyUseHTMLString: true,
				});
			}
		}
	},
	mounted() { },
	created() { }
}